import Vue from 'vue'
import App from './App.vue'
import vueCustomElement from 'vue-custom-element'
import 'document-register-element/build/document-register-element'
import ActionCableVue from 'actioncable-vue'
import VueRecord from '@codekraft-studio/vue-record'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueEasyLightbox from 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js'
import {
  faCheck as fasCheck,
  faCheckDouble as fasCheckDouble,
  faCircleExclamation as fasCircleExclamation,
  faCircle as fasCircle,
  faMagnifyingGlass as fasMagnifyingGlass,
  faEllipsisVertical as fasEllipsisVertical,
  faFaceLaughBeam as fasFaceLaughBeam,
  faPaperclip as fasPaperclip,
  faMicrophone as fasMicrophone,
  faRecycle as fasRecycle,
  faMessage as fasMessage,
  faBars as fasBars,
  faArrowDown as fasArrowDown,
  faSun as fasSun,
  faMoon as fasMoon,
  faPaperPlaneTop as fasPaperPlaneTop,
  faClock as fasClock,
  faBolt as fasBolt,
  faPenToSquare as fasPenToSquare,
  faTrashAlt as fasTrashAlt,
  faCircleNotch as fasCircleNotch,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  fasCheck,
  fasCheckDouble,
  fasCircleExclamation,
  fasCircle,
  fasMagnifyingGlass,
  fasEllipsisVertical,
  fasFaceLaughBeam,
  fasPaperclip,
  fasMicrophone,
  fasRecycle,
  fasMessage,
  fasBars,
  fasArrowDown,
  fasSun,
  fasMoon,
  fasPaperPlaneTop,
  fasClock,
  fasBolt,
  fasPenToSquare,
  fasTrashAlt,
  fasCircleNotch
)
const actionCableConfig = {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_WS_URI,
  connectImmediately: true,
}
Vue.use(ActionCableVue, actionCableConfig)
Vue.use(VueEasyLightbox)
Vue.use(vueCustomElement)
Vue.use(VueRecord)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
App.validations = {}
Vue.customElement('mobysuite-whatsapp', App)
